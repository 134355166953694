import request from '@/config/axios';

export default class DtgkService {
  newest = (siteId) => {
    return request({
      url: '/dtgkArgs/newest/' + siteId,
      method: 'get',
    });
  };
}
