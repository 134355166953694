<template>
    <div class='w-full'>
        <div class='flex flex-wrap gap-8'>
            <div v-if='polList.length === 0' class='w-full text-center' style='padding-top: 200px;'>
                <i class='pi pi-fw pi-exclamation-circle text-2xl text-400'></i>
                <div class='text-400 text-2xl'>无数据, 请检查数采仪是否上传了动态管控参数!</div>
            </div>
            <div style='width: 25rem;height:35rem;position: relative' v-for='item in polList' :key='item'>
                <img src='layout/images/machine.png' style='width:18rem;position: absolute' alt='设备图片'>
                <div
                    style='width: 13.3rem;height: 11.8rem;background: transparent;position: inherit;margin-top: 2.3rem;margin-left: 2.3rem;padding-right: 0.5rem;'
                    class='overflow-y-scroll'>
                    <div>
                        <div class='flex justify-content-between gap-1 border-bottom-1 border-bluegray-100'
                             v-for='arg in item.args'
                             :key='arg'><span style='color: rgb(68, 72, 109);'
                            class='text-xs'>{{ arg.name ?? arg.code }}:</span><span class='text-xs' style='color: rgb(68, 72, 109);'>{{ arg.value
                            }}</span></div>
                    </div>
                </div>
                <div style='position: inherit;width: 13.4rem;margin-left: 2.3rem;text-align: center;margin-top: 3rem'>
                    <h3 style='color: #44486D'>{{ item.polName ?? item.polId }}</h3></div>
            </div>
        </div>
    </div>
</template>
<script>
import DtgkService from '../service/DtgkService';

export default {
    watch: {
        siteId() {
            console.log(this.siteId);
            this.loadNewest();
        },
    },
    data() {
        return {
            polList: [],
        };
    },
    dtgkService: null,
    created() {
        this.dtgkService = new DtgkService();
    },
    mounted() {
        this.loadNewest();
    },
    methods: {
        loadNewest() {
            this.dtgkService.newest(this.$store.getters.currentSelectedSite.id).then((res) => {
                if (res.data.success) {
                    this.polList = res.data.data;
                }
            });
        },
    },

};
</script>
<style scoped>
</style>
